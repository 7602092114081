@import "../../../styles/utils";
@import "../../../styles/components";
.fixedPosition {
  position: fixed;
  top: 0;
  right:0;
  z-index: 900;
  padding-bottom:30px;
}
.buttonContainer {
  width: 100%;
  padding-top:20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right:25px;
  @include phone() {
    padding-right:0;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.menuBackground {
  @include phone() {
    background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1) 40%);
    animation: fadeInAnimation ease 0.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
      0% {
        background-position: 0;
      }
      100% {
        background-position:10% 0%;
      }
    }
  }
}

.signInButton {
  padding: 10px 35px 10px 35px !important;
  @include phone() {
    @include paddingHorizontal(35px);
    @include paddingVertical(18px);
    @include typography(2rem);
    width: 170px;
  }
}